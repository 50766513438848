export const ACTION_TYPES = {
	setActiveGuideFromLocalStorage: 'setActiveGuideFromLocalStorage',
	setActiveGuide: 'setActiveGuide',
	advanceGuide: 'advanceGuide',
	exitGuide: 'exitGuide'
};

export const MUTATION_TYPES = {
	setActiveGuide: 'setActiveGuide',
	setActiveStepIndex: 'setActiveStepIndex'
};

export const GETTER_TYPES = {
	activeGuide: 'activeGuide',
	activeStep: 'activeStep',
	activeStepNumber: 'activeStepNumber',
	totalSteps: 'totalSteps'
};
