import I18n from 'helpers/i18n';

export default {
	activeGuideId: '',
	activeStepIndex: 0,
	guides: [{
		id: 'gs-onboarding-configure-stripe',
		title: I18n.t('shared.guides.gs-onboarding-create-org.title'),
		activationTargetSelector: '#guide_gs_onboarding_configure_stripe',
		steps: [
			{
				targetSelector: '#manage-organizations-link .text',
				text: I18n.t('shared.guides.gs-onboarding-create-org.step_1'),
				hotspotPlacement: 'right',
				tooltipPlacement: 'bottom',
				completeTargetSelector: '#manage-organizations-link',
				beforeStepCallback: async () => {
					document.getElementById('manage-dropdown').focus();
					return new Promise((resolve) => setTimeout(resolve, 200));
				}
			},
			{
				targetSelector: '#guide-gs-org-selection',
				text: I18n.t('shared.guides.gs-onboarding-create-org.step_2'),
				hotspotPlacement: 'top',
				tooltipPlacement: 'top',
				completeTargetSelector: '#guide-gs-org-selection'
			},
			{
				targetSelector: '#growth-suite-settings-tab',
				text: I18n.t('shared.guides.gs-onboarding-create-org.step_3'),
				hotspotPlacement: 'right',
				tooltipPlacement: 'top',
				completeTargetSelector: '#growth-suite-settings-tab'
			},
			{
				targetSelector: '#growth-suite-settings-title',
				text: I18n.t('shared.guides.gs-onboarding-create-org.step_4'),
				hotspotPlacement: 'left',
				tooltipPlacement: 'top',
				completeTargetSelector: '#growth-suite-settings-submit'
			},
			{
				targetSelector: '.stripe-connect',
				text: I18n.t('shared.guides.gs-onboarding-create-org.step_5'),
				hotspotPlacement: 'right',
				tooltipPlacement: 'top',
				completeTargetSelector: '.stripe-connect'
			}
		]
	}, {
		id: 'gs-onboarding-prepare-services',
		title: I18n.t('shared.guides.gs-onboarding-create-services.title'),
		activationTargetSelector: '#guide_gs_onboarding_prepare_service',
		steps: [
			{
				targetSelector: '#manage-services-link',
				text: I18n.t('shared.guides.gs-onboarding-create-services.step_1'),
				hotspotPlacement: 'right',
				tooltipPlacement: 'bottom',
				completeTargetSelector: '#manage-services-link',
				beforeStepCallback: async () => {
					document.getElementById('manage-dropdown').focus();
					return new Promise((resolve) => setTimeout(resolve, 200));
				}
			}
		]
	}, {
		id: 'sl-notification-center',
		title: I18n.t('shared.guides.sl-notification-center.title'),
		activationTargetSelector: '#guide_sl_notification-center',
		steps: [
			{
				targetSelector: '.guide_notification-bell',
				text: I18n.t('shared.guides.sl-notification-center.step_1'),
				hotspotPlacement: 'bottom',
				tooltipPlacement: 'bottom',
				completeTargetSelector: '.guide_notification-bell'
			},
			{
				targetSelector: '.comms-center__drawer-button',
				text: I18n.t('shared.guides.sl-notification-center.step_2'),
				hotspotPlacement: 'left',
				tooltipPlacement: 'left',
				completeTargetSelector: '.drop-list__link',
				beforeStepCallback: async () => {
					return new Promise((resolve) => setTimeout(resolve, 200));
				}
			},
			{
				targetSelector: '#guide_notification-archive',
				text: I18n.t('shared.guides.sl-notification-center.step_3'),
				hotspotPlacement: 'bottom',
				tooltipPlacement: 'bottom',
				completeTargetSelector: '#guide_notification-archive'
			}
		]
	}, {
		id: 'client-reports-onboarding',
		title: I18n.t('shared.guides.client-reports-onboarding.title'),
		activationTargetSelector: '.cc-notification__content a[href="#client-reports-cta"]',
		steps: [
			{
				targetSelector: '.header__nav .nav__item [title="Clients"]',
				text: I18n.t('shared.guides.client-reports-onboarding.step_1'),
				hotspotPlacement: 'right',
				tooltipPlacement: 'bottom',
				completeTargetSelector: '.header__nav .nav__item [title="Clients"]'
			},
			{
				targetSelector: '#client-reports-tab a',
				text: I18n.t('shared.guides.client-reports-onboarding.step_2'),
				hotspotPlacement: 'right',
				tooltipPlacement: 'bottom',
				completeTargetSelector: '#client-reports-tab a'
			},
			{
				targetSelector: '#create-new-client-report',
				text: I18n.t('shared.guides.client-reports-onboarding.step_3'),
				hotspotPlacement: 'left',
				tooltipPlacement: 'bottom',
				completeTargetSelector: '#create-new-client-report'
			},
			{
				targetSelector: '.report__preview--new',
				text: I18n.t('shared.guides.client-reports-onboarding.step_4'),
				hotspotPlacement: 'right',
				tooltipPlacement: 'bottom',
				completeTargetSelector: '.report__preview--new'
			}
		]
	}]
};
