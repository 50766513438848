import { MUTATION_TYPES } from './types';

export default {
	[MUTATION_TYPES.setActiveGuide] (state, guideId) {
		state.activeGuideId = guideId;
	},

	[MUTATION_TYPES.setActiveStepIndex] (state, stepIndex) {
		state.activeStepIndex = stepIndex;
	}
};
