import { ACTION_TYPES, MUTATION_TYPES, GETTER_TYPES } from './types';

export default {
	[ACTION_TYPES.setActiveGuideFromLocalStorage] ({ commit }) {
		const activeGuideId = getActiveGuideIdFromLocalStorage();
		const activeStepIndex = getActiveStepIndexFromLocalStorage();
		commit(`${MUTATION_TYPES.setActiveGuide}`, activeGuideId);
		commit(`${MUTATION_TYPES.setActiveStepIndex}`, activeStepIndex);
	},

	[ACTION_TYPES.setActiveGuide] ({ commit }, guideId, stepIndex = 0) {
		setActiveGuideIdInLocalStorage(guideId);
		setActiveStepIndexInLocalStorage(stepIndex);
		commit(`${MUTATION_TYPES.setActiveGuide}`, guideId);
		commit(`${MUTATION_TYPES.setActiveStepIndex}`, stepIndex);
	},

	[ACTION_TYPES.advanceGuide] ({ commit, state, getters, dispatch }) {
		const activeGuide = getters[`${GETTER_TYPES.activeGuide}`];
		const nextStepIndex = state.activeStepIndex + 1;

		if (activeGuide && nextStepIndex + 1 <= activeGuide.steps.length) {
			setActiveStepIndexInLocalStorage(nextStepIndex);
			commit(`${MUTATION_TYPES.setActiveStepIndex}`, nextStepIndex);
		} else {
			dispatch(`${ACTION_TYPES.exitGuide}`);
		}
	},

	[ACTION_TYPES.exitGuide] ({ commit }) {
		localStorage.removeItem('activeGuideId');
		localStorage.removeItem('activeStepIndex');
		commit(`${MUTATION_TYPES.setActiveGuide}`, '');
		commit(`${MUTATION_TYPES.setActiveStepIndex}`, 0);
	}
};

const setActiveGuideIdInLocalStorage = (guideId) => {
	localStorage.setItem('activeGuideId', JSON.stringify({
		guideId
	}));
};

const setActiveStepIndexInLocalStorage = (stepIndex = 0) => {
	localStorage.setItem('activeStepIndex', JSON.stringify({
		stepIndex
	}));
};

const getActiveGuideIdFromLocalStorage = () => {
	const activeGuideIdPayload = JSON.parse(localStorage.getItem('activeGuideId'));
	if (activeGuideIdPayload) {
		return activeGuideIdPayload.guideId;
	}

	return null;
};

const getActiveStepIndexFromLocalStorage = () => {
	const activeStepIndexPayload = JSON.parse(localStorage.getItem('activeStepIndex'));
	if (activeStepIndexPayload) {
		return activeStepIndexPayload.stepIndex;
	}

	return 0;
};
