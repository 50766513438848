import { GETTER_TYPES } from './types';

export default {
	[GETTER_TYPES.activeGuide] (state) {
		return state.guides.find((guide) => guide.id === state.activeGuideId);
	},

	[GETTER_TYPES.activeStep] (state, getters) {
		const activeGuide = getters[`${GETTER_TYPES.activeGuide}`];

		if (!activeGuide) {
			return null;
		}

		return activeGuide.steps[state.activeStepIndex];
	},

	[GETTER_TYPES.activeStepNumber] (state) {
		return state.activeStepIndex + 1;
	},

	[GETTER_TYPES.totalSteps] (state, getters) {
		const activeGuide = getters[`${GETTER_TYPES.activeGuide}`];
		return activeGuide.steps.length;
	}
};
